import Image from "next/image";
import classes from "./ProductsSection.module.scss";

import voluumLogo from "../../public/images/home-products-voluum-logo.svg";
import zeroparkLogo from "../../public/images/home-products-zeropark-logo.svg";

import voluumHoverImg from "../../public/images/products-voluum-hover-image.png";
import voluumHoverDoodleImg from "../../public/images/products-hover-doodle-voluum.svg";
import zeroparkHoverImg from "../../public/images/products-zeropark-hover-image.png";
import zeroparkHoveDoodleImg from "../../public/images/products-hover-doodle-zeropark.svg";
import supportingHoverImg from "../../public/images/products-supporting-hover-image.png";
import supportingHoverDoodleImg from "../../public/images/products-hover-doodle-supporting.svg";
import cn from "classnames";
import { useIsMedium, useIsBelowDesktop } from "../../hooks/useMediaQuery";

import morphMobileBlue from "../../public/images/mobile-morphs/home/products-blue.svg";
import morphMobileYellow from "../../public/images/mobile-morphs/home/products-yellow.svg";
import { TextLink } from "../elements";

const ProductsSection = (props) => {
  const isMobile = useIsMedium();
  const isBelowDesktop = useIsBelowDesktop();

  const PRODUCTS = [
    {
      name: "Voluum",
      link: "/products/#voluum",
      logo: voluumLogo,
      hoverImg: voluumHoverImg,
      hoverDoodle: voluumHoverDoodleImg,
      content: (
        <p>
          Voluum is an <strong>award-winning ad tracker.</strong> Thousands of
          digital marketers and performance agencies around the globe benefit
          from its AI-powered technology and{" "}
          <strong>campaign auto-optimization features.</strong>
        </p>
      ),
    },
    {
      name: "Zeropark",
      link: "/products/#zeropark",
      logo: zeroparkLogo,
      hoverImg: zeroparkHoverImg,
      hoverDoodle: zeroparkHoveDoodleImg,
      content: (
        <p>
          Zeropark is <strong>a commerce media platform</strong>{" "}
          offering a suite of incremental advertising & monetization solutions{" "}
          that help brands connect with consumers during the key moments of the
          modern shopping journey.
        </p>
      ),
    },
    {
      name: "Voluum DB, Platform & Supporting Teams",
      link: "/products/#voluumdb",
      hoverImg: supportingHoverImg,
      hoverDoodle: supportingHoverDoodleImg,
      content: (
        <p>
          From maintaining a state-of-the-art <strong>database</strong> and the
          company’s entire <strong>AWS</strong> environment, to managing people,
          money & business relations – our platforms wouldn’t be so successful
          without the teams that <strong>support</strong> them.
        </p>
      ),
    },
  ];

  return (
    <section className={classes.products} id="products">
      {isBelowDesktop && (
        <>
          <Image
            src={morphMobileBlue}
            className={cn(classes.morphMobile, classes.morphMobileBlue)}
            alt=""
          />
          <Image
            src={morphMobileYellow}
            alt=""
            className={cn(classes.morphMobile, classes.morphMobileYellow)}
          />
        </>
      )}
      <div className={classes.inner}>
        <div className={classes.headingWrapper}>
          <h1
            className={classes.heading}
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            Products we are proud of
          </h1>
        </div>
        <div className={classes.productsWrapper}>
          {PRODUCTS.map(
            ({ name, link, logo, hoverImg, content, hoverDoodle }, index) => {
              const salDirection =
                index % 2 === 0 ? "slide-right" : "slide-left";

              return (
                <div
                  className={classes.productBoxWrapper}
                  key={name}
                  data-sal={salDirection}
                  data-sal-delay={300 + index * 100}
                  data-sal-easing="ease"
                >
                  <div className={classes.productBox}>
                    {logo ? (
                      <Image src={logo} alt={name} />
                    ) : (
                      <h3 className={classes.productBoxHeading}>{name}</h3>
                    )}
                    <div className={classes.content}>{content}</div>
                    <div className={classes.productBoxHoverWrapper}>
                      <div className={classes.hoverImgWrapper}>
                        <Image src={hoverImg} alt="" />
                      </div>
                    </div>
                    <div className={classes.productBoxHoverDoodle}>
                      <Image src={hoverDoodle} alt="" />
                    </div>
                    <div className={classes.productLinkWrapper}>
                      <TextLink href={link}>See more</TextLink>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

// ProductsSection.whyDidYouRender = true;
export default ProductsSection;
